import React, { StrictMode } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import MintTemplate from "../components/Mint/MintTemplate";

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
};
const Mint = () => {
  return (
    <StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <MintTemplate />
      </Web3ReactProvider>
    </StrictMode>
  );
};

export default Mint;
