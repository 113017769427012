import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Buffer } from "buffer";
import Mint from "./pages/Mint";
import NoPage from "./pages/NoPage";

window.Buffer = window.Buffer || Buffer;

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Mint />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
